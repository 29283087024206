import React from 'react'
import '../App.css';

function Nomadic() {
    return (
      <div className="page">
        Nomadic content
      </div>
    )
}
export default Nomadic