import React from 'react'
import '../App.css';

function Mountains() {

    return (
        <img className = 'mountains' src={('/img/mountains.svg')} alt ='mountains' />
    )
}

export default Mountains