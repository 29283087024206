import React from 'react'
import '../App.css';

function About() {
    return (
      <div className="page">
        about content
      </div>
    )
}
export default About