import React from 'react'
import '../App.css';

function Workday() {
    return (
      <div className="page">
        Workday content
      </div>
    )
}
export default Workday